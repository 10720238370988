import React from 'react';

const IconLoader = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <title>Loader Logo</title>
    <g>
      <g id="B" transform="translate(37.000000, 32.000000)">
        <path
          d="M 27.271 10.987 L 19.946 10.987 A 6.22 6.22 0 0 0 19.756 9.404 A 4.351 4.351 0 0 0 18.408 7.19 Q 17.08 6.02 14.822 5.86 A 10.439 10.439 0 0 0 14.087 5.835 A 10.313 10.313 0 0 0 12.525 5.946 Q 11.681 6.076 10.996 6.357 A 4.756 4.756 0 0 0 9.912 6.97 A 3.822 3.822 0 0 0 8.868 8.147 Q 8.423 8.946 8.423 9.961 A 3.131 3.131 0 0 0 9.384 12.225 A 5.021 5.021 0 0 0 10.168 12.866 A 11.404 11.404 0 0 0 11.491 13.62 Q 12.926 14.325 15.008 14.972 A 39.577 39.577 0 0 0 15.308 15.064 Q 21.558 16.944 24.414 19.727 A 9.256 9.256 0 0 1 27.269 26.467 A 11.922 11.922 0 0 1 27.271 26.66 A 9.763 9.763 0 0 1 26.716 30.022 A 8.383 8.383 0 0 1 23.779 33.899 Q 20.779 36.155 15.994 36.472 A 24.41 24.41 0 0 1 14.38 36.524 Q 10.278 36.524 6.909 35.022 A 13.699 13.699 0 0 1 4.112 33.378 A 10.654 10.654 0 0 1 1.77 30.908 A 10.317 10.317 0 0 1 0.029 25.727 A 12.8 12.8 0 0 1 0 24.854 L 7.349 24.854 A 6.997 6.997 0 0 0 7.761 27.359 Q 8.931 30.421 13.416 30.708 A 15.098 15.098 0 0 0 14.38 30.738 A 10.457 10.457 0 0 0 15.901 30.634 Q 16.724 30.513 17.394 30.25 A 4.768 4.768 0 0 0 18.457 29.676 A 3.382 3.382 0 0 0 19.893 27.228 A 4.527 4.527 0 0 0 19.922 26.709 Q 19.922 24.634 18.457 23.523 Q 16.992 22.412 13.184 21.179 A 58.747 58.747 0 0 1 10.958 20.412 Q 8.681 19.573 7.153 18.75 A 15.087 15.087 0 0 1 4.226 16.73 A 8.809 8.809 0 0 1 1.099 9.937 Q 1.099 7.056 2.722 4.798 A 9.686 9.686 0 0 1 5.462 2.26 A 12.967 12.967 0 0 1 7.385 1.27 A 16.378 16.378 0 0 1 11.806 0.137 A 20.557 20.557 0 0 1 14.209 0 A 17.979 17.979 0 0 1 17.916 0.367 A 14.317 14.317 0 0 1 20.996 1.38 A 11.546 11.546 0 0 1 23.995 3.354 A 10.211 10.211 0 0 1 25.623 5.274 A 10.126 10.126 0 0 1 27.267 10.688 A 12.231 12.231 0 0 1 27.271 10.987 Z"
          fill="currentColor"
        />
      </g>
      <path
        stroke="currentColor"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M 50, 5
                  L 11, 27
                  L 11, 72
                  L 50, 95
                  L 89, 73
                  L 89, 28 z"
      />
    </g>
  </svg>
);

export default IconLoader;
